import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { graphql } from 'gatsby'
import {Link} from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ReactWOW from 'react-wow'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Helmet } from "react-helmet"
import Inscription from '../components/Newsletter/Inscription'

import imgLivreBlancCompl from '../assets/images/livre_blanc_compliance.png'
import imgLivreBlancFSC from '../assets/images/livret-fsc.png'
import imgLivreBlancRemontees from '../assets/images/livret-remontees.png'

const kebabCase = string => string
        .normalize("NFD").replace(/\p{Diacritic}/gu, "")
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/[\s_]+/g, '-')
        .toLowerCase();    

const postTemplate = ({data}) => {
    console.log(data)
    const image = getImage(data.oneBlog.frontmatter.hero_image)
    return (
        <Layout>
            <Helmet>
                <title>{data.oneBlog.frontmatter.metaTitle}</title>
                <meta name="description" content={data.oneBlog.frontmatter.metaDescription} />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle={data.oneBlog.frontmatter.title}
                homePageText="Accueil"
                homePageUrl="/" 
                parentActive="Blog" 
                parentPageUrl="/blog"
                activePageText={data.oneBlog.frontmatter.title}
            /> 
            <section className="blog-details-area pt-70 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <GatsbyImage
                                        image={image}
                                        alt={data.oneBlog.frontmatter.hero_image_alt}
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <i className='bx bx-calendar'></i>
                                                <span>Date de publication</span>
                                                {data.oneBlog.frontmatter.date}
                                            </li>
                                            <li>
                                                <i className='bx bx-folder-open'></i>
                                                <span>Catégorie</span>
                                                <Link to={"/cat/"+kebabCase(data.oneBlog.frontmatter.category)} className={"default "+kebabCase(data.oneBlog.frontmatter.category)}>
                                                    {data.oneBlog.frontmatter.category}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className='bx bx-user'></i>
                                                <span>Auteur</span>
                                                {data.oneBlog.frontmatter.author}
                                            </li>
                                        </ul>
                                    </div>
                                    <p className='pt-30'><span className='time-to-read'>Temps de lecture : {data.oneBlog.frontmatter.readTime}</span></p>
                                    {/*<h3>data.oneBlog.frontmatter.title</h3>*/}
                                    <MDXRenderer>
                                        {data.oneBlog.body}
                                    </MDXRenderer>
                                    
                                    <ReactWOW delay='.1s' animation='fadeInRight'>
                                    <div id="button" className="btn-box">
                                        <AnchorLink to="/contact" className="default-btn">
                                            <i className="flaticon-right"></i> 
                                            Demander votre devis <span></span>
                                        </AnchorLink>
                                    </div>
                                    </ReactWOW>

                                </div>
                            </div>     
                        </div>
                        {/** Affichage des derniers articles */}
                        <div className="col-lg-4 col-md-12">
                            <div className="widget-area">
                                <div className="widget widget_tracer_posts_thumb">
                                    <h3 className="widget-title">Nos derniers articles</h3>
                                    {data.lastThree.nodes.map(node => (
                                        <article className="item" key={node.id}>
                                            <Link to={"/"+node.frontmatter.slug} className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <GatsbyImage
                                                        image={getImage(node.frontmatter.hero_image)}
                                                        alt={node.frontmatter.hero_image_alt}
                                                        className='vignette-article'
                                                    />
                                                </span>

                                            </Link>
                                            <div className="info">
                                                <span>{node.frontmatter.date}</span>
                                                <h4 className="title usmall">
                                                    <Link to={"/"+node.frontmatter.slug}>
                                                        {node.frontmatter.title}
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                    ))}
                                </div>
                                {/** Affichage des catégories */}
                                <div className="widget widget_categories">
                                    <h3 className="widget-title">Catégories</h3>
                                    <div className="tagcloud-custom">
                                        {data.cats.group.map(cat => (
                                            <Link to={"/cat/"+kebabCase(cat.category)} className={"default "+kebabCase(cat.category)} key={cat.category}>
                                                {cat.category} <span className="tag-link-count">{"("+cat.totalCount+")"}</span>
                                            </Link>
                                        ))}
                                    </div>
                                    <div className="tagcloud-all center">
                                        <Link to="/blog">
                                        Tous les articles
                                        </Link>
                                    </div>
                                </div>
                                {/** Affichage des livres blancs */}
                                <div className="widget-area">
                                    <div className="widget widget_tracer_posts_thumb">
                                        <h3 className="widget-title">Nos livres blancs</h3>
                                        <article className="item" >
                                            <Link to="/livre-blanc-compliance" className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <img src={imgLivreBlancCompl} alt="livre blanc compliance" />
                                                </span>
                                            </Link>
                                            <div className="info">
                                                <span>Compliance</span>
                                                <h4 className="title usmall">
                                                    <Link to="/livre-blanc-compliance">
                                                        Comment intégrer un dispositif de signalement dans votre programme compliance ?
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                        <article className="item" >
                                            <Link to="/livre-blanc-fsc" className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <img src={imgLivreBlancFSC} alt="livre blanc FSC" />
                                                </span>
                                            </Link>
                                            <div className="info">
                                                <span>Food Safety Culture</span>
                                                <h4 className="title usmall">
                                                    <Link to="/livre-blanc-fsc">
                                                        Les points clés pour la mise en place d’une culture qualité et sécurité des aliments
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                        <article className="item" >
                                            <Link to="/livre-blanc-remontees" className="thumb">
                                                <span className="fullimage cover bg" role="img">
                                                    <img src={imgLivreBlancRemontees} alt="livre blanc Remontées d'information" />
                                                </span>
                                            </Link>
                                            <div className="info">
                                                <span>Guides</span>
                                                <h4 className="title usmall">
                                                    <Link to="/livre-blanc-remontees">
                                                        Comment fluidifier et sécuriser vos remontées d'information grâce au digital ?
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="clear"></div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Inscription />
            <Footer />
        </Layout>
    )
}
export const query = graphql`
  query($slug: String!){
    oneBlog: mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "fr")
        metaTitle
        metaDescription
        title
        author
        category
        readTime
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    lastThree: allMdx (limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          id
          frontmatter {
            slug
            date(formatString: "MMM YYYY", locale: "fr")
            title
            hero_image {
                childImageSharp {
                  gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
          }
        }
    }
    cats: allMdx{
        group(field: frontmatter___category) {
			category: fieldValue
            totalCount
        }
    }
  }
`

export default postTemplate